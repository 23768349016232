import request from '@/utils/request'
import ENDPOINT from '@/config/endpoint'

export function getArtifacts(params) {
  return request({
    url: `${ENDPOINT.LIST_ARTIFACT_PUBLIC}`,
    method: 'get',
    params
  })
}

export function getArtifactById(params) {
  return request({
    url: `${ENDPOINT.ARTIFACT_PUBLIC}`,
    method: 'get',
    params
  })
}

export function getClassification(params) {
  return request({
    url: `${ENDPOINT.ARTIFACT_PUBLIC}/classification/simplify`,
    method: 'get',
    params
  })
}

export function listPlaceArtifacts(params) {
  return request({
    url: `${ENDPOINT.PLACE_PUBLIC}/artifact`,
    method: 'get',
    params
  })
}
