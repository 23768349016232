<template>
  <div class="card-item">
    <div class="image" @click="handleShowDetail">
      <img :src="data && data.avatar ? data.avatar.url : ''" alt="" />
    </div>
    <div class="card-item__content">
      <p
        class="card-item__content--title text-limited"
        :title="data.name"
        @click="handleShowDetail"
      >
        {{ data.name }}
      </p>
      <a
        href="javascript:;"
        class="card-item__content--link"
        @click="handleShowDetail"
      >
        {{ $t("shared.viewDetail") }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardItem',
  props: {
    data: Object
  },
  methods: {
    handleShowDetail() {
      this.$emit('showDetail', this.data)
    }
  }
}
</script>

<style lang="scss" scoped>
.card-item {
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  .image {
    position: relative;
    padding-top: 75%;
    border-radius: 5px;
    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
  &__content {
    padding-top: 100%;
    left: 0;
    right: 0;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    padding: 0 15px 15px 15px;
    color: #212529;
    border-radius: 8px;
    &--title {
      font-weight: 600;
      margin-bottom: 10px;
      font-size: 17px;
      cursor: pointer;
    }
    &--description {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      -webkit-box-orient: vertical;
      font-size: 13px;
      margin-bottom: 5px;
      line-height: 18px;
    }
    &--link {
      font-size: 14px;
      color: #1174d4;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
