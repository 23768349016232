<template>
  <el-dialog :visible="visible" :fullscreen="fullscreen" width="700px" @close="handleClose">
    <div class="iframe-body">
      <iframe :src="url" frameborder="0" width="100%" height="100%"></iframe>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'ShowVrTour',
  props: {
    visible: Boolean,
    url: String
  },
  data() {
    return {
      fullscreen: false
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.iframe-body {
  height: 70vh;
}
</style>
