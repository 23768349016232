<template>
  <el-dialog :visible="visible" width="60%" top="50px" :custom-class="'show-fullscreen-image'" @close="handleClose" @open="handleOpen">
    <fullscreen :fullscreen.sync="fullscreen">
      <button class="btn-close" @click="close">
        <i class="fas fa-times" />
      </button>
      <splide v-if="images.length" :options="options">
        <splide-slide v-for="slide in images" :key="slide">
          <img :src="slide" @click="fullscreen = !fullscreen">
        </splide-slide>
      </splide>
      <button class="btn-fullscreen" @click="fullscreen = !fullscreen">
        <i class="fas fa-expand"></i>
      </button>
    </fullscreen>
  </el-dialog>
</template>

<script>
export default {
  name: 'ShowDetailImage',
  props: {
    visible: Boolean,
    listImage: Array,
    selectedIndex: Number
  },
  data() {
    return {
      fullscreen: false,
      options: {
        type: 'loop',
        width: '100%',
        pagination: false,
        arrows: false
      },
      images: []
    }
  },
  watch: {
    visible() {
      this.images = []
      this.$nextTick(() => {
        this.images = this.listImage
      })
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
    handleOpen() {
      this.options.start = this.selectedIndex
    },

    close() {
      if (this.fullscreen) {
        this.fullscreen = false
      } else {
        this.handleClose()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  outline: none;
  font-size: 24px;
  border: none;
  background: #000;
  color: #fff;
  padding: 5px 10px;
  cursor: pointer;
  border-bottom-left-radius: 10px;
}
.btn-fullscreen {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  outline: none;
  font-size: 24px;
  border: none;
  background: #000;
  color: #fff;
  padding: 5px 10px;
  cursor: pointer;
  border-top-left-radius: 10px;
}
</style>
<style lang="scss">
.show-fullscreen-image {
  /* .fullscreen {
    .splide {
      &__track {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  } */
  .splide--loop {
    position: relative;
    padding-top: 0;
    height: 100% !important;
    .splide__track {
      position: relative !important;
      height: 100% !important;
      .splide__list {
        height: 100% !important;
        .splide__slide {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100% !important;
          img {
            max-width: 100% !important;
            max-height: 100% !important;
            object-fit: cover !important;
          }
        }
      }
    }
  }
}
</style>
